import React from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import "./App.css";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {
  Page,
  BlobProvider,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import "typeface-roboto";
import QAudioRecorder from "./components/QAudioRecorder";

//Need to detect browser and device - done
//Need solution for safari on iOS - done
//Try https://ai.github.io/audio-recorder-polyfill/ for safari and edge - done
//Need to test chrome on android - done
//Look at https://addpipe.com/html-media-capture-demo/ - use for mobile browsers based on https://caniuse.com/#feat=html-media-capture

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
});

const stylesPDF = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const PDFDoc = (list) => (
  <Document>
    {list.map((item, i) => (
      <Page key={i} size="A4" style={stylesPDF.page}>
        <View style={stylesPDF.section}>
          <Image source={item.blob} />
        </View>
      </Page>
    ))}
  </Document>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCamera: false,
      showAudioRec: false,
      buttonText: "Show Camera/Webcam",
      buttonAudioText: "Show Audio Recorder",
      imgList: [],
      count: 1,
      showSubmit: false,
      showPDF: false,
      canvasData: {},
      blobAudioURL: "",
      showSubmitAudio: false,
      blobAudio: {},
      processing: false,
      error: "",
      loading: true,
    };
    this.onTakePhoto = this.onTakePhoto.bind(this);
  }

  componentDidMount = () => {
    axios.get("/studenthw/data").then((res) => {
      this.setState({ canvasData: res.data, loading: false });
    });
    this.setState({ loading: false });
  };

  onTakePhoto(dataUri) {
    // Do stuff with the dataUri photo...
    const obj = {
      img: dataUri,
      title: `image${this.state.count}`,
      blob: `${dataUri}`,
    };
    if (this.state.imgList.length === 0) {
      this.setState({
        showSubmit: true,
        imgList: [...this.state.imgList, obj],
      });
    } else {
      this.setState({ imgList: [...this.state.imgList, obj] });
    }
  }

  onCameraBtn() {
    let cameraText = "";
    this.state.showCamera
      ? (cameraText = "Show Camera/Webcam")
      : (cameraText = "Hide Camera/Webcam");
    if (this.state.showAudioRec) {
      this.setState({
        showAudioRec: false,
        buttonAudioText: "Show Audio Recorder",
      });
    }
    this.setState({
      showCamera: !this.state.showCamera,
      buttonText: cameraText,
    });
  }

  //Record audio view
  onAudioRecBtn() {
    let audioRecText = "";
    this.state.showAudioRec
      ? (audioRecText = "Show Audio Recorder")
      : (audioRecText = "Hide Audio Recorder");
    if (audioRecText === "Show Audio Recorder") {
      this.setState({ showSubmitAudio: false });
    } else {
      if (this.state.blobAudioURL !== "") {
        this.setState({ showSubmitAudio: true });
      }
    }
    if (this.state.showCamera) {
      this.setState({ showCamera: false, buttonText: "Show Camera/Webcam" });
    }
    this.setState({
      showAudioRec: !this.state.showAudioRec,
      buttonAudioText: audioRecText,
    });
  }

  processImages() {
    this.setState({ showPDF: true, showSubmit: false, showCamera: false });
  }

  getAudio = async (data) => {
    this.setState({
      blobAudioURL: data.blobAudioURL,
      showSubmitAudio: data.showSubmitAudio,
      blobAudio: data.blobAudio,
    });
  };

  cleanupFilename = (_name) => {
    const _newName = _name.replace(/\s+/g, "-").replace(/[!@#$%^&*|]+/g, "");
    return _newName;
  };

  processAudio = async (_canvasData) => {
    console.log("processing audio");
    this.setState({ processing: true });
    let reader = new FileReader();
    reader.readAsDataURL(this.state.blobAudio);
    reader.onloadend = function () {
      let base64data = reader.result;
      let filesObj = {};
      filesObj[
        `${_canvasData.context_label
          .replace(/\s+/g, "-")
          .replace(
            /[!@#$%^&*|]+/g,
            ""
          )}_${_canvasData.custom_canvas_assignment_title
          .replace(/\s+/g, "-")
          .replace(/[!@#$%^&*|]+/g, "")}`
      ] = base64data.toString();
      console.log(filesObj);
      let jsonStr = JSON.stringify({ files: filesObj });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //Pass back url to canvas
      axios
        .post(
          "https://ks8vedwzji.execute-api.eu-central-1.amazonaws.com/v2/s3-upload",
          jsonStr,
          config
        )
        .then((res) => {
          let fileData = JSON.parse(res.data);
          const returnurl = _canvasData.ext_content_return_url;
          //const returnurl = 'https://canvas.qasid.com/courses/3670/external_content/success/external_tool_dialog';
          //const params = "?return_type=file&text="+fileData.files[0].filename+".pdf&url="+encodeURIComponent(fileData.files[0].s3location);
          const params =
            "?return_type=url&url=" +
            encodeURIComponent(fileData.files[0].s3location);
          window.location.replace(returnurl + params);
        });
    };
  };

  //Add a button for record audio & one for record video
  //Record video view

  render() {
    const {
      showCamera,
      showAudioRec,
      buttonText,
      buttonAudioText,
      imgList,
      showSubmit,
      showPDF,
      canvasData,
      showSubmitAudio,
      error,
      loading,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className="App">
        {loading && <CircularProgress />}
        {!loading && error !== "" && (
          <Box p={1} component="span">
            <h4>{error}</h4>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ error: "" })}
            >
              Reset
            </Button>
          </Box>
        )}
        {!loading && (
          <Box>
            <Box p={1} component="span">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.onCameraBtn()}
              >
                {buttonText}
              </Button>
            </Box>
            <Box p={1} component="span">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.onAudioRecBtn()}
              >
                {buttonAudioText}
              </Button>
            </Box>

            {showCamera && (
              <Camera
                onTakePhoto={(dataUri) => {
                  this.onTakePhoto(dataUri);
                }}
              />
            )}
            {showAudioRec && (
              <QAudioRecorder
                onAudioRecorded={this.getAudio}
                recordedAudio={this.state.blobAudioURL}
              />
            )}
            <GridList cellHeight={160} className={classes.gridList} cols={3}>
              {imgList.map((tile) => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                  <img src={`${tile.img}`} alt={tile.title} />
                </GridListTile>
              ))}
            </GridList>
            {showSubmit && (
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.processImages()}
                >
                  Upload Images
                </Button>
              </Box>
            )}
            {showSubmitAudio && (
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.processAudio(this.state.canvasData)}
                  disabled={this.state.processing}
                >
                  Upload Audio
                </Button>
              </Box>
            )}
            {showPDF && (
              <BlobProvider document={PDFDoc(imgList)}>
                {({ blob, url, loading, error }) => {
                  if (blob !== null) {
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                      let base64data = reader.result;
                      let filesObj = {};
                      filesObj[
                        `${canvasData.context_label
                          .replace(/\s+/g, "-")
                          .replace(
                            /[!@#$%^&*|]+/g,
                            ""
                          )}_${canvasData.custom_canvas_assignment_title
                          .replace(/\s+/g, "-")
                          .replace(/[!@#$%^&*|]+/g, "")}`
                      ] = base64data.toString();
                      let jsonStr = JSON.stringify({ files: filesObj });
                      let config = {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      };
                      axios
                        .post(
                          "https://ks8vedwzji.execute-api.eu-central-1.amazonaws.com/v2/s3-upload",
                          jsonStr,
                          config
                        )
                        .then((res) => {
                          let fileData = JSON.parse(res.data);
                          const returnurl = canvasData.ext_content_return_url;
                          //const returnurl = 'https://canvas.qasid.com/courses/3670/external_content/success/external_tool_dialog';
                          //const params = "?return_type=file&text="+fileData.files[0].filename+".pdf&url="+encodeURIComponent(fileData.files[0].s3location);
                          const params =
                            "?return_type=url&url=" +
                            encodeURIComponent(fileData.files[0].s3location);
                          window.location.replace(returnurl + params);
                        });
                    };
                  }
                  return <div>Processing Data. Please wait...</div>;
                }}
              </BlobProvider>
            )}
          </Box>
        )}
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
